.choosemodal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.choosemodalcontent {
  position: relative;
  width: 100%;
  height: calc(100%);
  z-index: 2000;
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  overflow: scroll;
  max-height: 100%;
  padding-bottom: 100px;
  max-height: 570px;
  overflow-x: hidden;
}
.displaynone {
  display: none;
}
.closezone {
  transition: 0.2s;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  right: 0;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 2;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  background-color: rgba(218, 218, 218, 0.274);
}
.choosezone {
  background-color: var(--bkg);
  z-index: 3;
  /* overflow: scroll; */
  height: auto;
  width: 810px;
  max-height: 570px;
  min-height: 250px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 25px 50px 9px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  color: white;
  position: relative;
  padding-bottom: 0;
  overflow: hidden;
}

.select {
  width: 100%;
  border: 1px solid var(--borderinput);
  padding-left: 0px;
  padding-right: 0px;
  outline: none;
  height: 27px;
  font-size: var(--veryverylittlefont);
  background-color: #fff;
  option {
    border: 1px solid var(--borderinput);
  }
}

.tdinput {
  position: relative;

  border: 1px solid rgb(122, 122, 122);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
  height: auto;
  p {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .input {
    width: 25px;
    position: relative;
    left: 5px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    border-radius: 2px;
    outline: none;
    border: 1px solid #7f7f7f81;
    width: 20px;
    height: 20px;
    margin: 0;
  }

  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    left: 0;
    position: relative;
    cursor: pointer;
    filter: 1 !important ;
  }
  [type="checkbox"]:not(:checked) {
    background-color: transparent;
  }
  [type="checkbox"]:checked {
    background-image: url(../../img/checked.png);
    background-size: 95%;
    filter: invert(1);
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid #4444449a;
    padding: 0;
    background-color: rgb(201 147 0 / 83%);
  }
}
.opacity {
  opacity: 0.12;
}
.foilLayerzone {
  display: flex;
  flex-wrap: nowrap;
}
.foilLayer {
  border: 1px solid rgb(108, 108, 108);
  width: fit-content;
  border-radius: 100px;
  margin-top: 7px;
  margin-right: 5px;
  padding: 2px 10px;
  font-size: var(--veryverylittlefont);
  display: flex;
  align-items: center;
  align-content: center;
  p {
    font-size: var(--veryverylittlefont);
    margin: 0;
    padding: 0;
  }

  svg {
    cursor: pointer;
    opacity: 0.7;
    width: 10px;
    margin-left: 10px;
    margin-bottom: 2px;
  }
  svg:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.texte {
  font-size: calc(9px + 0.1vw);
  font-weight: 300;
  text-align: center;
  width: 100%;
  font-family: "Poppins" !important;
  padding-top: 10px;
}

.errorMessage {
  text-align: center;
  color: red !important;
  margin-bottom: 10px;
  font-size: calc(13px + 0.2vw) !important;
  margin-top: 10px;
}

.disabled {
  background-color: #d5d5db !important;
  cursor: default !important;
}

.validate {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 15px;
  padding-bottom: 25px;
  bottom: 0;
  background-color: white;
  left: 0;

  z-index: 10000;

  .button {
    background-color: var(--bluebutton);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    p {
      margin: 0;
      color: var(--textcolorinvert);
    }
  }
  .button:hover {
    background-color: var(--bluebuttonhover);
  }
}

.choosemodalcontent::-webkit-scrollbar:vertical {
  display: block;
  width: 5px;
  border-radius: 20px;
}
.choosemodalcontent::-webkit-scrollbar:hover {
  display: block;
  width: 7px !important;
  border-radius: 20px;
}
.choosemodalcontent::-webkit-scrollbar-thumb {
  border-right-width: 5px;
  border-radius: 30px;
  background-color: #d9d9d9;
  /* border-top-right-radius: 9px 5px; */
  /* border-bottom-right-radius: 9px 5px; */
}
.choosemodalcontent::-webkit-scrollbar-thumb:hover {
  background-color: #aaaaaa;
}

.checkboxContainer {
  display: flex;
  gap: 10px; /* Adjust the gap between checkboxes if needed */
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33%;
  }
  label {
    display: flex;
    align-items: center;
    margin: 0;
    width: calc(100% - 50px);
  }
  input {
    display: flex;
    left: auto !important;
    position: relative !important;
    margin-left: 0;
  }
}

.layerText {
  font-size: calc(16px + 0.1vw) !important;
}

.disabledInput {
  cursor: not-allowed !important;
}
