.menu {
  position: relative;
  top: 0px;
  height: 100vh;
  width: 49px;
  // min-width: 200px;
  // max-width: 240px;
  transition: 0.4s;
  background-color: var(--menuleft);

  //  background-color: var(--navleft);
  z-index: 100;
  color: white;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // background-color: var(--menuconnect);
    width: 100%;
    // min-width: 200px;
    // max-width: 240px;
    //  margin-top: 65px;
    height: 100%;

    .organisation {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 46px;
      width: 90%;
      background-color: #00000000;
      border-radius: 12px;
      margin: 2px 0px;

      p {
        display: flex;
        margin: 0;
        font-size: var(--littlefont);
        font-weight: 600;
        padding: 30px;
        color: white;
      }
    }
    .organisation:hover {
      background-color: #00000075;
      cursor: pointer;
    }
    .organisation:active {
      background-color: #121212;
      cursor: pointer;
    }
    .logocontainer {
      display: flex;
      width: 90%;

      h1 {
        font-size: 1rem;
        overflow: scroll;
        white-space: nowrap;
      }
    }
    .logocontainer {
      height: 58px;
      display: flex;
      align-items: center;
      .logo {
        height: 40px;
        margin-left: 2px;
        margin-bottom: 4px;
      }
    }
    .logomgicontainer {
      display: flex;
      width: 90%;
      position: absolute;
      bottom: -6px;

      .logomgibot {
        display: flex;

        height: auto;
        height: 64px;
        align-items: center;
        width: 100%;
        background-color: none;
        overflow: hidden;

        img {
          opacity: 0.9;
          fill: var(--B_W);
          height: 34px;
          width: 34px;
          margin-left: 4px;
        }
        p {
          opacity: 0.6;
          display: flex;
          // font-family:"Young";
          font-size: calc(0.1em + var(--veryverylittlefont));
          letter-spacing: -1px;
          font-weight: 450;
          padding: 30px;
          white-space: nowrap;
          margin: 0;
          padding: 0;
          margin-left: 6px;
          margin-top: calc(4px);
          color: white;
        }
      }
    }

    .container_list {
      display: flex;
      height: 100%;
      width: 90%;
      overflow: scroll;

      .list1 {
        display: flex;

        height: auto;
        width: 100%;
        background-color: none;

        p {
          display: flex;
          align-items: center;
          margin: 0;
          color: white;
          white-space: nowrap;
        }

        img {
          filter: invert(1);
          width: 30px;
          height: 30px;
          margin-left: 7px;
        }

        ul {
          width: 100%;
          margin: 0;
          padding: 0;
          margin-top: 0;
        }
        .lilith {
          //  width: 100%;
          display: flex;
          cursor: pointer;
          opacity: 0.8;
          width: 100%;
          svg {
            fill: var(--B_W);
            height: 30px;
            width: 30px;

            margin-left: 7px;
          }
        }
        .lilith:active {
          color: var(--select);
          svg {
            fill: var(--select);
            opacity: 0.9;
          }
        }
        .lilith:hover {
          opacity: 0.9;
          svg {
            opacity: 0.9;
          }
        }

        li.li1 {
          flex-wrap: wrap;
          width: 100%;
          display: flex;
          height: auto;
          margin-top: 22px;
          margin-bottom: 22px;
          list-style: none;
          font-size: var(--veryverylittlefont);
          font-weight: 600;

          .text_select {
            opacity: 0.9;
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-left: 14px;
            cursor: pointer;
            white-space: nowrap;
            img {
              opacity: 0.3;
              transform: scale(0.75);
              filter: var(--remy);
            }
            svg {
              opacity: 0.3;
              transform: scale(0.75);
            }
          }

          .text_select:hover {
            opacity: 1;
            img {
              opacity: 0.9;
            }
            svg {
              opacity: 0.9;
            }
          }
        }
      }
      .text_select:active {
        color: var(--select) !important;
        img {
          opacity: 1;
        }
        svg {
          opacity: 1;
        }
      }
      .active {
        color: var(--select) !important;
        svg {
          fill: var(--select) !important;
        }
        p,
        a {
          color: var(--select) !important;
        }
      }
    }

    li {
      list-style: none;
    }

    .li2 {
      opacity: 0.8;
      list-style: none;
      width: 70%;
      margin-left: 38px;
      margin-top: 6px;
      font-weight: 500;
      cursor: pointer;
      // color: var(--B_W);
      color: white;
    }
    p {
      font-size: var(--veryverylittlefonttable);
      color: white;
    }
  }
  .li2:hover {
    opacity: 0.8;
    color: var(--select);
  }
  .li2:active {
    opacity: 0.9;
    color: var(--select);
  }
}

.openMenu {
  display: none;
}
.fliparrow {
  transform: rotate(180deg) scale(0.75) !important;
}

a {
  // color: var(--B_W);
  color: white !important;
  text-decoration: none !important;
  opacity: 1;
}
a:hover {
  color: var(--select) !important;
  text-decoration: none !important;
  opacity: 1;
}

.menu:hover {
  width: calc(240px + 0.4vw);
}
