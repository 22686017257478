// @import "../styles/variables.scss";
// @import "../styles/title.scss";
tr:hover {
  .hover_actions_container {
    display: flex !important;
  }
}
.hover_actions_container {
  display: none;
  height: calc(100% - 2px);
  position: absolute;
  background-color: var(--menuconnect);

  justify-content: center;
  align-items: center;
  /* width: calc(100px + 9.5%); */
  margin-right: 0px;
  padding-left: 25px;
  padding-right: 10px;
  overflow: visible;
  right: 15px;
  @media (min-width: 1600px) {
    right: calc(60px + 0.3vw);
  }
  a {
    margin: 0px 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 45px;
    img {
      width: 55%;
      opacity: 0.45;
    }
  }
  a:hover {
    background-color: #dbe4ff94;
    border-radius: 100px;
  }
  a:active {
    background-color: #dbe4ff;
    border-radius: 100px;
  }
}
.hover_actions_container_click {
  background-color: #f2f2f6 !important;
}
.actionIcons {
  padding: 0px;
  margin: 0px;
}

// PErsonnalisation des titles

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background-color: black;
  color: white;
  font-size: var(--veryverylittlefont);
  position: absolute;
  padding: 2px 10px 2px 10px;
  bottom: -2.2em;
  right: 0;
  white-space: nowrap;
  border-radius: 6px;

  opacity: 0;
  border: none;
  z-index: 10;
  visibility: hidden;
  font-family: "Poppins";
  font-weight: 500;
}
[data-title] {
  position: relative;
}
