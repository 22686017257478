.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiOutlinedInput-notchedOutline {
  border-color: black !important;
}
.MuiInputLabel-marginDense {
  color: rgb(60 60 60 / 60%) !important;
  font-weight: 500 !important;
}
input::placeholder {
  color: rgb(56 55 55 / 59%) !important;
}
.WithStyles\(ForwardRef\(InputBase\)\)-input-19 {
  border: 1px solid black !important;
}
.WithStyles\(ForwardRef\(InputBase\)\)-input-25 {
  border: 1px solid black !important;
}

.MuiFormLabel-asterisk {
  display: none !important;
}
.MuiInput-underline:after {
  border: none !important;
}
.MuiSelect-select .MuiInput-underline:before {
  /* border: none !important; */
}
.MuiTypography-colorTextSecondary {
  font-size: 0.75rem !important;
  letter-spacing: -1px !important;
}
.MuiOutlinedInput-input {
  padding: 0.5vw 12px !important;
}
.MuiSelect-select.MuiSelect-select {
  padding-top: 8px;
  padding-bottom: 8px;
}
* input:-internal-autofill-selected {
  background-color: red !important;
}
input:-webkit-autofill {
  background-color: red !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: rgb(245, 208, 89) !important;
  transition: background-color 50000000000000s ease-in-out 0s;
}

.MuiInputBase-root {
  /* color: rgb(0 0 0 / 70%) !important; */
}
.MuiInputAdornment-positionEnd {
  margin: 0;
}
.MuiOutlinedInput-adornedEnd {
  padding-right: 8px !important;
}
.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  height: 2em !important;
}
.MuiOutlinedInput-inputAdornedEnd {
  padding-right: 4px !important;
}
