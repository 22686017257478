/* =================================================

Name:  ink-coverage CSS
Author: MGI Digital Technology, MGI Labs
Design and Developed by: MGI Labs

NB: Ce fichier contient le style du ink-coverage.

=================================================== */
@import "./variable.scss";
::-webkit-scrollbar {
  display: none;
}

form::-webkit-scrollbar:vertical {
  display: block;
  width: 5px;
  border-radius: 20px;
}
form::-webkit-scrollbar:hover {
  display: block;
  width: 7px !important;
  border-radius: 20px;
}
form::-webkit-scrollbar-thumb {
  border-right-width: 5px;
  border-radius: 30px;
  background-color: #eeeeee;
  /* border-top-right-radius: 9px 5px; */
  /* border-bottom-right-radius: 9px 5px; */
}
form::-webkit-scrollbar-thumb:hover {
  background-color: #d1cfcf;
}
//FONTS

/* Poppins */

@font-face {
  font-family: "Poppins";
  font-weight: 900;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 800;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 300;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 100;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  src: local("Montserrat"),
    url(../../fonts/Montserrat/Montserrat-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  src: local("Montserrat"),
    url(../../fonts/Montserrat/Montserrat-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  src: local("Montserrat"),
    url(../../fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  src: local("Montserrat"),
    url(../../fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  src: local("Montserrat"),
    url(../../fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  src: local("Montserrat"),
    url(../../fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  src: local("Montserrat"),
    url(../../fonts/Montserrat/Montserrat-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  src: local("Montserrat"),
    url(../../fonts/Montserrat/Montserrat-ExtraLight.ttf) format("truetype");
}

@media (min-width: 960px) {
  h5 {
    font-size: 1.4rem !important;
    font-weight: 600 !important;
  }
}
@media (min-width: 600px) {
  h5 {
    font-size: 1.25rem !important;
    font-weight: 600 !important;
  }
}
//END FONTS

html {
  font-size: calc(10px + 0.74vw);
  font-weight: 500;
  font-family: "Montserrat";
}
body {
  background-color: var(--bkg) !important;
}

// switch page

p {
  font-weight: 500;
}
td {
  font-weight: 600 !important;
  font-size: 1rem !important;
}
th {
  font-size: 1rem !important;
}
input {
  color: black !important;
}

input::placeholder {
  color: rgb(56 55 55 / 59%) !important;
}
::placeholder {
  color: rgb(56 55 55 / 59%) !important;
}

.inputNoPdfZone {
  height: 28%;
  display: flex;
  border: 1px solid #c2c2c261;
  border-radius: 4px;
  background-color: #f3f3f39a;
  .inputzone {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .labelButton {
    font-family: "Montserrat";
    font-size: var(--verylittlefont);
    background-color: var(--bluebutton);
    color: var(--textcolorinvert);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    outline: none;
    cursor: pointer;
    z-index: 10;
  }
}

.inputpdfzone {
  margin-top: 15px;
  height: 72%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  .inputzone {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: unset;
    label {
      font-family: "Montserrat";
      font-size: var(--verylittlefont);
      background-color: var(--bluebutton);
      color: var(--textcolorinvert);
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 5px;
      padding-bottom: 5px;
      outline: none;
      cursor: pointer;
      z-index: 10;
    }
    label:hover {
      background-color: var(--bluebuttonhover);
    }
    .image {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .textinput {
      margin: 0;
      padding: 0;
    }
  }
}
.contentzone {
  position: relative;
  width: -webkit-fill-available;
  p,
  div,
  td,
  th,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  input,
  select,
  option {
    font-size: calc(16px + 0.2vw);
    font-weight: 500;
    font-family: "Montserrat" !important;
    color: var(--textcolor);
  }
}
.container {
  width: 100%;
  height: calc(100% - 123px);
  background-color: var(--bkg);
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-left: var(--paddingrl);
  padding-right: var(--paddingrl);
  overflow: scroll;
  padding-bottom: 59px !important;
}
.containerinfo {
  width: 37.7%;

  background-color: var(--bkg);
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
}

.containerResultFull {
  width: 100% !important;
}

.containerresult {
  width: 62.3%;
  // height: 100%;
  background-color: var(--bkg);
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 1410px) {
  .containerinfo {
    width: 43.5%;
  }
  .containerresult {
    width: 56.5%;
  }
}

.logomgi {
  position: fixed;
  top: 0;
  left: 0px;
  z-index: 101;
  display: flex;
  height: 47px;
  align-items: center;
  padding-left: var(--paddingrl) !important;
  img {
    height: 30px;
    margin: 0;
    margin-bottom: 7px;

    margin-right: 25px;
  }
  h1 {
    font-weight: 600;
    font-size: var(--bigfont);
    margin: 0;
  }
}
.estimationbtn {
  position: fixed;
  top: 7px;
  right: 250px;
  z-index: 101;
  label {
    font-family: "Montserrat";
    font-size: var(--verylittlefont);
    background-color: var(--bluebutton);
    color: var(--textcolorinvert);
    padding-left: 20px;
    padding-right: 20px;
    /* padding-top: 3px; */
    /* padding-bottom: 3px; */
    outline: none;
    cursor: pointer;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  label:hover {
    background-color: var(--bluebuttonhover);
  }
}
.coverage {
  width: calc(100% - 30px);
  // height: calc(100% - 50px);
  background-color: var(--bkg);
  color: var(--white);

  // padding-left: var(--paddingrl);
  // padding-right: var(--paddingrl);
  // overflow: scroll;
}

//importzone
@import "./Designer.scss";
.inputzone {
  width: 100%;
  position: relative;
}
.creation_import_file {
  height: calc(240px + 10vh) !important;
  margin-bottom: 0px !important;
  width: 100%;
}
.creation_import_instruction .imgzone {
  max-height: 100%;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -8px;
}
.creation_import_instruction p {
  font-size: calc(10px + 0.3vw) !important;
  font-family: "worksans";
}
.creation_import_instruction {
  height: calc(240px + 10vh) !important;
  width: 100%;
}
.creation_importingimg {
  width: calc(34px + 0.3vw) !important;
  max-width: 54px !important;
  filter: invert(0) !important;
}

//loading

.loadingzone {
  height: 100% !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  border-radius: 8px;
  padding-top: 8px;

  //  border: 0.5px solid #abaeb360;
  // background-color: #ffffff17;
}
.loading {
  position: relative;
}
.ldsring {
  position: relative;
  width: 100%;
  height: 80px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ldsring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--bluebutton);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--bluebutton) transparent transparent transparent;
}
.ldsring div:nth-child(1) {
  animation-delay: -0.45s;
}
.ldsring div:nth-child(2) {
  animation-delay: -0.3s;
}
.ldsring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

//table
.result {
  width: 100%;
  margin-bottom: 5px !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: var(--bkgzone);
  padding: 10px;
  border: 1px solid var(--bodercontainer);

  .jobnamezone {
    position: relative;
    width: calc(100% - 30px);
    height: fit-content;
    margin: 0;
    padding: 0;
    border: 1px solid rgba(255, 255, 255, 0.308);
    .jobname {
      border-bottom: 1px solid rgba(255, 255, 255, 0.137);
      margin: 0;
      padding: 0;
      overflow: hidden;
      width: 100%;
      margin-bottom: 7px;
      display: flex;
      justify-content: space-between;
      p {
        margin: 0;
        padding: 0;
        // padding-left: 15px;
        // padding-right: 15px;
        height: 100%;
        width: 60%;
        display: flex;
        align-items: center;

        text-transform: uppercase;
        font-weight: 500;
        color: var(--textcolor);
        font-size: var(--verylittlefont);
        opacity: 0.9;
      }
      input {
        width: 100%;
      }
    }
    .job {
      margin: 0;
      padding: 0;
      overflow: hidden;
      width: 100%;
      width: calc(100%);
      position: relative;
      height: 27px;
      p {
        margin: 0;
        padding: 0;
        // padding-left: 15px;
        // padding-right: 15px;
        // padding-top: 10px;
        // padding-bottom: 10px;
        height: 100%;
        width: 100%;
        align-items: center;

        font-size: var(--verylittlefont);
        text-overflow: ellipsis;

        white-space: nowrap;
        position: absolute;
        margin-top: -3px;
        display: block;
        overflow: hidden;
      }
    }
    .thumbnailszonecolor {
      border: 1px solid var(--borderimg);
      background-color: var(--backgroundimg);
    }
    .thumbnailszone {
      margin: 0;
      padding: 0;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      padding-top: 5px;
      padding-bottom: 10px;
      margin-top: 12px;
      margin-bottom: 10px;

      .thumbnails {
        display: block;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;

        p {
          margin: 0;
          padding: 0;
          margin-left: 5%;
          font-size: calc(12px + 0.2vw);
          margin-bottom: 5px;
        }
        img {
          max-width: 90%;
          max-height: 180px;
          margin-left: 5%;
        }
      }
    }
    .infozone {
      width: 100%;
      margin: 0;
      padding: 0;
      .oneinfo {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 3px;
        p {
          margin: 0;
          padding: 0;
          font-size: var(--verylittlefont);
        }
      }
    }
    .onecontent {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 4px;
      margin-bottom: 4px;
      p {
        margin: 0;
        font-size: var(--verylittlefont);
      }
      .sides {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        width: calc(128px);
        margin-top: 4px;
        margin-bottom: 4px;
        input {
          border: 1px solid var(--borderinput);
          width: calc(100% - 2px);
          margin: 0;
          padding: 0;
          margin: 0;
          padding: 0;
          outline: none;
          padding-left: 8px;
          padding-right: 8px;
          font-size: var(--verylittlefont);
          height: 25px;
        }
        .paddinginput {
          padding-left: 8px;
          padding-right: 49px;
        }
        .paddinginput2 {
          padding-left: 5px;
          padding-right: 22px;
        }
        .paddinginput3 {
          padding-left: 5px;
          padding-right: 0px;
        }
      }
      .littleside {
        width: calc(75px);
      }
    }
  }
  .tablezone {
    position: relative;
    width: 70%;
    border: 1px solid rgba(255, 255, 255, 0.308);
    height: fit-content;

    .tabletitle {
      width: 100%;

      tr {
        width: 100%;
        flex-wrap: wrap;

        th {
          width: 25%;
          text-align: center;
          height: 39px;

          border-bottom: 1px solid rgba(255, 255, 255, 0.137);
          p {
            padding: 0;
            margin: 0;
          }
        }
      }
    }
    .tablecontent {
      tr:first-child {
        td {
          border-top: none;
        }
      }
      tr {
        td {
          tr:first-child {
            td {
              border-top: none !important;
            }
          }
        }
      }

      tr {
        td:first-child {
          border-right: 1px solid rgba(255, 255, 255, 0.137);
        }
        td:last-child {
          tr {
            td {
              border-right: none;
            }
          }
        }
      }
      tr {
        width: 100%;
        flex-wrap: wrap;

        td {
          width: 25%;
          text-align: center;
          overflow: hidden;
          border-right: none;
          border-top: 1px solid rgba(255, 255, 255, 0.137);
          p {
            padding: 0;
            margin: 0;
            padding-top: 2px;
            padding-bottom: 2px;
            font-weight: 400;
          }
          tr {
            td {
              margin: 0;
              padding: 0;
              position: relative;
              border-top: 1px solid rgba(255, 255, 255, 0.137) !important;
              p {
                padding: 0;
                margin: 0;
                padding-top: 2px;
                padding-bottom: 2px;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: 4px;
                padding-left: 4px;
                white-space: nowrap;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                font-weight: 400;
              }
            }
          }
        }
      }

      .leftcolor {
        position: absolute;
        left: 5px;
        width: 5px;
        height: 80%;
        margin-top: 2%;
      }
      .cyan {
        background-color: cyan;
      }
      .magenta {
        background-color: magenta;
      }
      .yellow {
        background-color: yellow;
      }
      .black {
        background-color: black;
      }
    }
  }
}

.onecontainerfocused {
  border: 2px solid var(--bluebutton) !important;
}

.onecontainer {
  width: 100%;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: var(--bkgzone);
  border: 1px solid var(--bodercontainer);
  padding: 10px;
  .informations {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 30px);
    p {
      padding: 0;
      margin: 0;
      font-size: var(--verylittlefont);
    }
    .titlezone {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .title {
        text-transform: uppercase;
        font-family: "Montserrat";
        margin-bottom: 7px;
        font-weight: 500;
        color: var(--textcolor);
        font-size: var(--verylittlefont);
        opacity: 0.9;
      }
      .sides {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: calc(128px + 1.5vw);
        margin-top: 4px;
        margin-bottom: 4px;
        p {
          width: calc(62px + 0.75vw);
          display: flex;
          justify-content: center;
          font-size: var(--verylittlefont);
        }
        .sideA {
          position: relative;
          input {
            border: 1px solid var(--borderinput);
            width: calc(50px);
          }
        }
      }
    }
    .onecontent {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 2px;
      margin-bottom: 2px;

      .sides {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        width: calc(128px + 1.5vw);
        margin-top: 4px;
        margin-bottom: 4px;
        input {
          border: 1px solid var(--borderinput);
          width: calc(100% - 8px);
          margin: 0;
          padding: 0;
          outline: none;
          padding-left: 8px;
          padding-right: 8px;
          font-size: var(--veryverylittlefont);
          height: 25px;
        }
        .paddinginput {
          padding-left: 8px;
          padding-right: 49px;
        }
        .paddinginput2 {
          padding-left: 5px;
          padding-right: 22px;
        }
        .paddinginput3 {
          padding-left: 5px;
          padding-right: 5px;
          font-size: var(--verylittlefont);
        }
        .sideB {
          margin-left: 2px;
          width: 75px;
          display: flex;
          position: relative;
        }
        .sideA {
          width: calc(62px + 0.75vw);
          display: flex;
          position: relative;
        }
      }
    }

    .ontwolinetext {
      font-size: var(--verylittlefont) !important;
      line-height: 170%;
    }
    .ontwoline {
      margin-top: 8px;
      margin-bottom: 8px;
      align-items: flex-start;
      .sides {
        margin-top: 0;
      }
    }
  }
}

.onecontainer {
  width: 100%;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  .consommables {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 30px);
    p {
      padding: 0;
      margin: 0;
      font-size: var(--verylittlefont);
    }
    .titlezone {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .title {
        text-transform: uppercase;
        font-family: "Montserrat";
        margin-bottom: 7px;
        font-weight: 500;
        color: var(--textcolor);
        font-size: var(--verylittlefont);
        opacity: 0.9;
      }
      .sides {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        width: calc(150px);
        margin-top: 4px;
        margin-bottom: 4px;
        p {
          width: calc(62px + 0.75vw);
          display: flex;
          justify-content: center;
          font-size: var(--verylittlefont);
        }
        .sideA {
          position: relative;
          input {
            border: 1px solid var(--borderinput);
            width: calc(50px);
          }
        }
      }
    }
    .onecontent {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 2px;
      margin-bottom: 2px;
      .sides {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        width: calc(128px);
        margin-top: 4px;
        margin-bottom: 4px;
        input {
          border: 1px solid var(--borderinput);
          width: calc(100% - 2px);
          margin: 0;
          padding: 0;
          margin: 0;
          padding: 0;
          outline: none;
          padding-left: 8px;
          padding-right: 8px;
          font-size: var(--verylittlefont);
          height: 29px;
        }
        .paddinginput {
          padding-left: 8px;
          padding-right: 49px;
        }
        .paddinginput2 {
          padding-left: 5px;
          padding-right: 22px;
        }
      }
    }
  }
}

.globaltotal {
  width: 100%;
  display: flex;
  align-items: center;
  //position: absolute;

  border: 1px solid rgba(255, 255, 255, 0.233);
  height: 40px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: none;
  left: -1px;
  bottom: -42px;
  .totaltitle {
    width: calc(33.33333%);

    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: relative;
    p {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 0;
      padding: 0;
      font-weight: 500;
    }
  }
  .total {
    width: calc(66.66666%);
    background-color: rgb(243, 243, 243);
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    p {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      margin: 0;
      padding: 0;
      font-weight: 500;
    }
  }
}
.globaltotal2 {
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;

  border: 1px solid rgba(255, 255, 255, 0.233);
  height: 40px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: none;
  left: -1px;
  bottom: -42px;
  .totaltitle2 {
    width: calc(50% - 8px);

    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: relative;

    p {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      font-weight: 500;
    }
  }
  .total {
    width: 25%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    p {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      font-weight: 500;
    }
  }
}
.borderright {
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  border-right: 1px solid rgba(255, 255, 255, 0.233);
}

.tdinput {
  position: relative;

  width: 100%;
  .input {
    width: 25px;
    position: absolute;
    left: 5px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    border-radius: 2px;
    outline: none;
    border: 1px solid #afafaf81;
    width: 20px;
    height: 20px;
  }

  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    left: 0;
    position: relative;
    cursor: pointer;
    filter: 1 !important ;
  }
  [type="checkbox"]:not(:checked) {
    background-color: transparent;
  }
  [type="checkbox"]:checked {
    background-image: url(../../img/check.svg);
    background-size: 95%;
    filter: invert(1);
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid #4444449a;
    padding: 0;
    background-color: rgba(201, 147, 0, 0.26);
  }
}
.opacity {
  opacity: 0.12;
}
.resultzone {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  bottom: 51px;
  right: 0;
  //background-color: #2c2c2c;
  width: calc(62% - 30px);
  height: 59px;
  color: var(--textcolorinvert);
  background-color: var(--bkg);
  @media (max-width: 1410px) {
    width: calc(57.2% - 31px - 1.25vw);
    // bottom: 59px;
  }

  padding-right: var(--paddingrl);
  .results {
    width: calc(100%);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    p {
      width: auto;
      margin: 0;
      color: var(--textcolorinvert);
      padding: 0;
    }
  }
  .savebtn {
    width: calc(150px);
    display: flex;
    justify-content: center;
    border: 1px solid white;
    p {
      margin: 0;
      padding: 0;
      color: var(--textcolorinvert);
      width: fit-content;
    }
  }
}
.sideA {
  position: relative;
  display: flex;
  align-items: center;
}
.sideB {
  position: relative;
  display: flex;
  align-items: center;
}
.unite {
  position: absolute;
  right: 8px;

  font-size: var(--verylittlefont);
}
.unite2 {
  position: absolute;
  bottom: -20px;
  left: 50%;

  font-size: var(--veryveryverylittlefont);
}
.paddinginput {
  padding-left: 8px;
  padding-right: 49px;
}
.paddinginput2 {
  padding-left: 5px;
  padding-right: 22px;
}
.select {
  width: 100%;
  border: 1px solid var(--borderinput);
  padding-left: 0px;
  padding-right: 0px;
  outline: none;
  height: 27px;
  font-size: var(--verylittlefont);
  background-color: white;
  option {
    border: 1px solid var(--borderinput);
    font-size: var(--verylittlefont);
  }
}
.displaynone {
  display: none;
}
.inputimport {
  opacity: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  margin-top: 5px;
  z-index: 15;
  cursor: pointer;
}
.inputimportzone {
  border: 1px solid #c2c2c261;
  height: 100%;
  position: absolute;
  width: 100%;
  margin-top: 5px;
  border-radius: 4px;
  background-color: #f3f3f39a;
  z-index: 1;
}

.totalTable {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  td {
    color: black;
    border: 1px solid black;
    width: 25%;
    font-size: calc(16px + 0.2vw) !important;
    font-weight: 500 !important;
    text-align: center;
    height: 40px;
    background-color: #c2c2c261;
  }
}

.elementsTableContainer {
  height: 101px;
  position: absolute;
  display: flex;
  bottom: 0px;
  left: 0;
  width: calc(38% - 27px);
  background-color: #fefefe;
  margin-left: 30px;
  padding-left: var(--paddingrl);
  margin-bottom: 10px;
  .elementsTable {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 91px;
    color: var(--textcolorinvert);
    padding-left: var(--paddingrl);
    background-color: #fefefe;
    border: 1px solid black;
    .onecontent {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 4px;
      margin-bottom: 4px;
      p {
        margin: 0;
        font-size: var(--verylittlefont);
      }
      .sides {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        width: calc(128px);
        margin-top: 4px;
        margin-bottom: 4px;
        margin-right: 20px;
        input {
          border: 1px solid var(--borderinput);
          width: calc(100% - 2px);
          margin: 0;
          padding: 0;
          margin: 0;
          padding: 0;
          outline: none;
          padding-left: 8px;
          padding-right: 8px;
          font-size: var(--verylittlefont);
          height: 25px;
        }
        .paddinginput {
          padding-left: 8px;
          padding-right: 49px;
        }
        .paddinginput2 {
          padding-left: 5px;
          padding-right: 22px;
        }
        .paddinginput3 {
          padding-left: 5px;
          padding-right: 0px;
        }
      }
      .littleside {
        width: calc(75px);
      }
    }
  }
}

.saveZone {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  bottom: 0px;
  right: 0;
  //background-color: #2c2c2c;
  width: calc(62% - 30px);
  height: 60px;
  color: var(--textcolorinvert);
  padding-right: var(--paddingrl);
  background-color: var(--bkg);
  padding-bottom: 10px !important;
  padding-top: 10px !important;

  @media (max-width: 1410px) {
    width: calc(57.2% - 31px - 1.25vw);

    // height: 63px;
    // align-items: flex-start;
  }

  .newEstimate {
    //background-color: var(--bluebutton);
    //color: white;
    border: 1px solid black;
    width: 25%;
  }

  .saveButtons {
    background-color: var(--bluebutton);
    color: white;
    border: none;
    width: 33%;
    height: 40px;
    border-radius: 2px;
    font-weight: 500;
  }

  .saveButtons2 {
    background-color: var(--bluebutton);
    color: white;
    border: none;
    width: 49.5%;
    height: 40px;
    border-radius: 2px;
    font-weight: 500;
  }
}

.detailcontent {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  //ADD SELECT

  .select {
    position: relative;
    min-width: 20px;
    width: calc(55px + 0.4vw);
    outline: none;
    font-size: var(--verylittlefont);
    height: auto;
  }
  svg {
    position: absolute;
    right: 12px;
    top: calc(50% - 2px);
    width: 11px;
    height: 6px;
    stroke-width: 2px;
    stroke: #666a74;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    pointer-events: none;
  }
  select {
    -webkit-appearance: none;
    padding: 0px 25px 2px 7px;
    width: 100%;
    height: 25px;
    // border: 1px solid #707070;
    border: none;
    border-radius: 0px;
    background: white;
    box-shadow: none;
    cursor: pointer;
    font-family: inherit;
    font-size: 16px;
    transition: all 150ms ease;
    text-align: center;
    font-family: "Montserrat";
    font-weight: 500;
    text-transform: uppercase;
    font-size: var(--verylittlefont);
    outline: none;
    &:required:invalid {
      color: #5a667f;
    }
    option {
      width: 100%;
      max-width: 200px;
      color: var(--B_W);
      &[value=""][disabled] {
        display: none;
      }
      &:focus {
        outline: none;
        border-color: #0077ff;
        box-shadow: 0 0 0 2px rgba(#0077ff, 0.2);
      }
      &:hover + svg {
        stroke: #0077ff;
      }
    }
  }
  .sprites {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    user-select: none;
  }

  //END
}

.longText {
  width: 60%;
}
.longTextSmall {
  font-size: var(--veryverylittlefont) !important;
}
.titlemachine {
  position: absolute;
  top: 0;
  height: 64px;
  display: flex;
  align-content: center;
  left: 0;
  align-items: center;
  width: 100%;
  z-index: 0;
  padding-left: var(--paddingrl);
  p {
    margin: 0;
    padding: 0;
    z-index: 10000;
  }
}

.inputButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  .labelButton {
    text-align: center;
  }
}

.inputPercent {
  padding-right: 15px !important;
}

.inputerror {
  border: 1px solid red !important;
}

.informationsbutton {
  display: flex;
  align-items: center;
  padding-left: 10px;
  svg {
    fill: var(--B_W);
    height: 18px;
    width: 18px;
    opacity: 0.8;
    cursor: pointer;
  }
  svg:hover {
    opacity: 1;
  }
}

.infoFormat {
  display: flex;
  align-items: center;
}

[data-title-info]:hover:after {
  opacity: 1;
  // transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title-info]:after {
  content: attr(data-title-info);
  background-color: white;
  border: 1px solid black;
  color: black;
  font-size: var(--veryverylittlefont);
  position: absolute;
  padding: 2px 10px 2px 10px;
  bottom: -2.2em;
  width: auto;
  left: 0;
  white-space: nowrap;
  border-radius: 6px;

  opacity: 0;
  z-index: 10;
  visibility: hidden;
  font-family: "Poppins";
  font-weight: 500;
}
[data-title-info] {
  position: relative;
}

.logoMachine {
  filter: invert(1);
  height: 40px;
}

.marginbottom {
  margin-bottom: 15px !important;
}

.select {
  margin-right: 0px;
}

.sqmPrice {
  display: flex;
  width: 100%;
  margin-top: 38px;
  .sqmTableDiv {
    width: 100%;
  }
}

.disabledInput {
  cursor: not-allowed !important;
}
