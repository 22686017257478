//Zone d'IMPORT
.creation_import {
  padding: 20px !important;
  padding-left: 25px !important;
  display: flex !important;
  align-items: center !important;
}
.creation_import_file {
  height: 220px;
  background: var(--gray);
  padding: 4px;
  position: relative;
  border-radius: 10px;

  -moz-opacity: 0;

  opacity: 0;
  z-index: 2;
  margin-bottom: 20px !important;
}

.creation_import_file::before {
  content: "";
  position: absolute;
  background: var(--gray);
  font-size: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.creation_import_file::after {
  text-align: center;
  position: absolute;
  color: white;
  // font-size: $font10_4;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}
@media (min-width: 1600px) {
  .creation_import_instruction {
    height: 165px !important;
  }
  .creation_import_file {
    height: 165px !important;
  }
}
.creation_import_instruction {
  position: absolute;
  height: 220px;
  top: 0;

  border-radius: 8px;
  padding-top: 8px !important;
  background-color: transparent;
  border: 0.5px solid #abaeb3;
  background-color: #bbbbbb;

  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);

  font-family: "Poppins";

  font-weight: 500;

  p {
    text-align: center;
    font-size: calc(13px + 0.3vw);
    color: #757575;
  }

  div {
    justify-content: center;
    align-items: center;
    display: flex;

    .creation_importingimg {
      width: 4.3%;
      max-width: 48px;
      filter: invert(0.8);

      opacity: 0.5;
    }
  }
}
.creation_import_preview {
  p {
    text-align: left;
  }
}
