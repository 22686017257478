:root {
  --paddingrl: calc(30px);

  --white: #000000;

  // --bkg: #f9f9fa;
  --bkg: #ffffff;
  --bkgzone: #ffffff;
  --bodercontainer: #e9e9e9;
  --blue: #2e35db;
  --gray: #646464;
  --bluebutton: #064bbb;
  --bluebuttonhover: #063a90;

  --textcolor: #000000;
  --textcolorinvert: #ffffff;
  --select: #ffffff;
  --borderinput: #b1b1b1b4;
  --bodertable: #b1b1b1;
  --bodertable2: #707070;
  --bodertable3: #707070;
  --menuleft: #16181d;

  --menuconnect: #fafafd;

  --backgroundimg: #f9f9fa;
  --borderimg: #d0d0d09d;

  // --bodertable: rgba(255, 255, 255, 0.308);
  // --bodertable2: rgba(255, 255, 255, 0.137);
  // --bodertable3: rgba(255, 255, 255, 0.233);

  @media (max-width: 1500px) {
    //fonts size
    --veryverybigfont: calc(29px + 6 * ((100vw - 320px) / 680)); //20
    --verybigfont: calc(13px + 6 * ((100vw - 320px) / 680)); //18
    --bigfont: calc(12px + 6 * ((100vw - 320px) / 680)); //17
    --regularfont: calc(11px + 4 * ((100vw - 320px) / 680)); //15
    --medfont: calc(14px + 3 * ((100vw - 320px) / 680)); //15 + 018
    --semilittlefont: calc(14px + 0.18vw); //14 + 018
    --littlefont: calc(13px + 3 * ((100vw - 320px) / 680)); //12 +O.18
    --verylittlefont: calc(10px + 4 * ((100vw - 320px) / 680)); //11
    --veryverylittlefont: calc(9px + 3 * ((100vw - 320px) / 680)); //11
    --veryveryverylittlefont: calc(8px + 3 * ((100vw - 320px) / 680)); //11
    --veryverylittlefonttable: calc(10px + 3 * ((100vw - 340px) / 680)); //11
  }

  @media (max-width: 576px) {
    //fonts size
    --veryverybigfont: calc(29px + 6 * ((100vw - 320px) / 680)); //20
    --verybigfont: calc(13px + 6 * ((100vw - 320px) / 680)); //18
    --bigfont: calc(12px + 6 * ((100vw - 320px) / 680)); //17
    --regularfont: calc(11px + 4 * ((100vw - 320px) / 680)); //15
    --medfont: calc(15px + 0.18vw); //15 + 018
    --semilittlefont: calc(14px + 0.18vw); //14 + 018
    --littlefont: 14px; //12 +O.18
    --verylittlefont: 13px; //11
    --veryverylittlefont: 12px; //11
    --veryveryverylittlefont: 11px; //11
    --veryverylittlefonttable: 10px; //11
  }
  //fonts size

  //fonts size
  --veryverybigfont: calc(29px + 6 * ((100vw - 320px) / 680)); //20
  --verybigfont: calc(13px + 6 * ((100vw - 320px) / 680)); //18
  --bigfont: calc(12px + 6 * ((100vw - 320px) / 680)); //17
  --regularfont: calc(14px + 4 * ((100vw - 320px) / 680)); //15
  --medfont: calc(13px + 4 * ((100vw - 320px) / 680)); //15 + 018
  --semilittlefont: calc(12px + 4 * ((100vw - 320px) / 680)); //14 + 018
  --littlefont: calc(12px + 4 * ((100vw - 320px) / 680)); //12
  --verylittlefont: calc(10px + 4 * ((100vw - 320px) / 680)); //11
  --veryverylittlefont: calc(8px + 3 * ((100vw - 320px) / 680)); //11
  --veryveryverylittlefont: calc(7px + 3 * ((100vw - 320px) / 680)); //11
  --veryverylittlefonttable: calc(10px + 3 * ((100vw - 320px) / 680)); //11
}
