.choosemodal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .choosezone {
    background-color: var(--bkg);
    z-index: 3;
    height: auto;
    width: 500px;
    max-height: 570px;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 25px 50px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    color: white;
    position: relative;
    overflow: hidden;

    .choosemodalcontent {
      position: relative;
      width: 100%;
      height: calc(100%);
      z-index: 2000;
      display: flex;
      flex-wrap: wrap;
      overflow: scroll;
      overflow: scroll;
      max-height: 100%;
      max-height: 570px;

      .title {
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .equipmentElement {
        border: 1px solid black;
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        padding: 10px;
        cursor: pointer;
        &:hover {
          background-color: var(--menuconnect);
        }
      }
    }
  }
}

.logoMachine {
  height: 40px;
  filter: invert(1);
}
