.dashboardzone {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 38px;
}
.displaynone {
  display: none;
}
.dashboard {
  width: 50%;
  height: 100%;
  background-color: var(--bkg);
  svg {
    height: 100%;
    rect {
      fill: var(--bkg);
      height: 100%;
    }
    path {
      stroke: var(--bkg);
    }
  }
}
.dashtext {
  width: 100%;
  height: 100%;
  background-color: var(--bkg);
  display: flex;
  align-items: center;
  .dashtextzone {
    width: 100%;
  }
}

.textzone {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  .legendzone {
    width: 20%;
  }
  .heightalign {
    width: 80%;
    display: flex;
    justify-content: space-between;
    p {
      width: fit-content;
      margin: 0;
    }
  }
}
.legend {
  height: 20px;
  width: 20px;
  border-radius: 100px;
  border: 1px solid rgb(67, 67, 67);
  margin: auto;
}
.orange {
  background-color: #780695;
}
.blue1 {
  background-color: #dd3910;
}
.blue2 {
  background-color: #179718;
}
.blue3 {
  background-color: #3366cc;
}
.yellow1 {
  background-color: #20c1e3;
}
.yellow2 {
  background-color: #fd9900;
}
.yellow3 {
  background-color: #fdcd00;
}
.rose {
  background-color: #fa6bad;
}
.totalcost {
  display: flex;
  justify-content: space-between;
}

.resultTable {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.459);
  border-bottom: none;
  border-collapse: collapse;
  background-color: white;
  th {
    height: 40px;
    font-weight: 500;
    font-size: var(--verylittlefont);
    font-family: "Montserrat" !important;
    text-transform: uppercase;
  }
  .headBg {
    .headBglittlefont {
      font-size: var(--verylittlefont) !important;
      opacity: 0.85;
    }
  }

  td {
    border: 1px solid rgba(0, 0, 0, 0.459);
    font-size: calc(16px + 0.2vw) !important;
    font-weight: 500 !important;
    text-align: center;
    height: 40px;
    width: 25%;
  }
}
.tablecontentheight100 {
  td {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: none;
  }
}

.expand {
  right: 0;
  position: absolute;
  top: 0;
  height: 40px;
  width: 40px;
  border-left: 1px solid rgba(0, 0, 0, 0.459);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.thFontSize {
  font-size: var(--verylittlefont) !important;
}

.flexTd {
  height: 120px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.height100 {
  height: 100% !important;
}
.height100noborderright {
  height: 100% !important;
  border-right: none !important;
}
.height40 {
  height: 40px !important;
}
.height40noborderright {
  height: 40px !important;
  border-right: none !important;
}
.tablecontentheight100:nth-child(2n) {
  td {
    border-top: 1px solid rgba(0, 0, 0, 0.459) !important;
  }
}

.innerTh {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--verylittlefont) !important;
}

.innerThSmall {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--veryveryverylittlefont) !important;
}

.subTableInnerTh {
  width: 60% !important;
}

.subInnerTh {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--veryveryverylittlefont) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.459);
  border-bottom: 1px solid rgba(0, 0, 0, 0.459);
}

.subInnerTd {
  width: 25% !important;
  border-top: none !important;
  border-left: none !important;
}

.borderBot {
  border: none !important;
  border-left: 1px solid rgba(0, 0, 0, 0.459) !important;
}

.innerTr .innerTd:first-child {
  border: none !important;
  border-right: 1px solid rgba(0, 0, 0, 0.459) !important;

  // border-bottom: 1px solid rgba(0, 0, 0, 0.459) !important;
}
.noborderright {
  border-right: none !important;
}
.resultTable {
  tbody {
    tr:nth-child(2n) {
      td {
        tr {
          td:last-child {
            border: none;
            border-left: 1px solid rgba(0, 0, 0, 0.459) !important;
          }
        }
      }
    }
  }
}
.innerTr {
  display: table;
  width: 100%;
  border: none !important;
}
.innerTd {
  width: 20% !important;
}

.subTable {
  width: 60% !important;
}

.foilSplit {
  opacity: 0.6;
}

.headBg {
  background-color: white !important;
  tr th {
    font-weight: 500;
    color: var(--textcolor);
    font-size: var(--verylittlefont);
    opacity: 0.9;

    text-align: center;
  }
}
