/* =================================================

Name:  ink-coverage CSS
Author: MGI Digital Technology, MGI Labs
Design and Developed by: MGI Labs

NB: Ce fichier contient le style du ink-coverage.

=================================================== */
@import "./variable.scss";
::-webkit-scrollbar {
  display: none;
}

form::-webkit-scrollbar:vertical {
  display: block;
  width: 5px;
  border-radius: 20px;
}
form::-webkit-scrollbar:hover {
  display: block;
  width: 7px !important;
  border-radius: 20px;
}
form::-webkit-scrollbar-thumb {
  border-right-width: 5px;
  border-radius: 30px;
  background-color: #eeeeee;
  /* border-top-right-radius: 9px 5px; */
  /* border-bottom-right-radius: 9px 5px; */
}
form::-webkit-scrollbar-thumb:hover {
  background-color: #d1cfcf;
}
//FONTS

/* Poppins */

@font-face {
  font-family: "Poppins";
  font-weight: 900;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 800;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 300;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 100;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  src: local("Montserrat"),
    url(../../fonts/Montserrat/Montserrat-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  src: local("Montserrat"),
    url(../../fonts/Montserrat/Montserrat-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  src: local("Montserrat"),
    url(../../fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  src: local("Montserrat"),
    url(../../fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  src: local("Montserrat"),
    url(../../fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  src: local("Montserrat"),
    url(../../fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  src: local("Montserrat"),
    url(../../fonts/Montserrat/Montserrat-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  src: local("Montserrat"),
    url(../../fonts/Montserrat/Montserrat-ExtraLight.ttf) format("truetype");
}

@media (min-width: 960px) {
  h5 {
    font-size: 1.4rem !important;
    font-weight: 600 !important;
  }
}
@media (min-width: 600px) {
  h5 {
    font-size: 1.25rem !important;
    font-weight: 600 !important;
  }
}
//END FONTS

html {
  font-size: calc(10px + 0.74vw);
  font-weight: 500;
  font-family: "Montserrat";
}
body {
  background-color: var(--bkg) !important;
}

// switch page

p,
div,
td,
th,
h1,
h2,
h3,
h4,
h5,
h6,
input,
select,
option {
  font-size: calc(16px + 0.2vw);
  font-weight: 500;
  font-family: "Montserrat" !important;
}
p {
  font-weight: 500;
}
td {
  font-weight: 600 !important;
  font-size: 1rem !important;
}
th {
  font-size: 1rem !important;
}
input {
  color: black !important;
}

input::placeholder {
  color: rgb(56 55 55 / 59%) !important;
}
::placeholder {
  color: rgb(56 55 55 / 59%) !important;
}
.inputpdfzone {
  margin-top: 45px;
}
.container {
  width: 100%;
  height: 100%;
  background-color: var(--bkg);
  margin: 0;
  padding: 0;
}
.navbar {
  width: 100%;
  height: 47px;
  padding-left: var(--paddingrl);
  padding-right: var(--paddingrl);
  background-color: rgba(0, 0, 0, 0.10196078431372549);
  .logomgi {
    height: 100%;
    display: flex;
    align-items: center;
    img {
      height: 55%;
    }
  }
}
.coverage {
  width: calc(100% - calc(var(--paddingrl) + var(--paddingrl)));
  height: calc(100% - 50px);
  background-color: var(--bkg);
  color: var(--white);
  padding-left: var(--paddingrl);
  padding-right: var(--paddingrl);
  overflow: scroll;
}

//importzone
@import "./Designer.scss";
.inputzone {
  width: 100%;
  position: relative;
}
.creation_import_file {
  height: calc(240px + 10vh) !important;
  margin-bottom: 0px !important;
  width: 100%;
}
.creation_import_instruction .imgzone {
  max-height: 100%;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -8px;
}
.creation_import_instruction p {
  font-size: calc(10px + 0.3vw) !important;
  font-family: "worksans";
}
.creation_import_instruction {
  height: calc(240px + 10vh) !important;
  width: 100%;
}
.creation_importingimg {
  width: calc(34px + 0.3vw) !important;
  max-width: 54px !important;
  filter: invert(0) !important;
  margin-top: -45px;
}

//loading

.loadingzone {
  height: 100% !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  border-radius: 8px;
  // padding-top: 8px;
  // border: 0.5px solid #abaeb360;
  // background-color: #bbbbbb17;
}
.inputimportzone {
  border: 1px solid #c2c2c261;
  height: 100%;
  position: absolute;
  width: 100%;
  margin-top: 5px;
  border-radius: 4px;
  background-color: #f3f3f39a;
}
.loading {
  position: relative;
}
.ldsring {
  position: relative;
  width: 100%;
  height: 80px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ldsring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--bluebutton);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--bluebutton) transparent transparent transparent;
}
.ldsring div:nth-child(1) {
  animation-delay: -0.45s;
}
.ldsring div:nth-child(2) {
  animation-delay: -0.3s;
}
.ldsring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

//table
.result {
  width: 100%;
  padding-bottom: 0px !important;
  padding-top: 16px !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .jobnamezone {
    position: relative;
    width: calc(30% - 30px);
    height: fit-content;
    margin: 0;
    padding: 0;
    border: 1px solid rgba(255, 255, 255, 0.308);
    .jobname {
      height: 42px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.137);
      margin: 0;
      padding: 0;
      overflow: hidden;
      p {
        margin: 0;
        padding: 0;
        padding-left: 15px;
        padding-right: 15px;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
      }
    }
    .job {
      margin: 0;
      padding: 0;
      overflow: hidden;
      /* width: 100%; */
      width: calc(100%);
      position: relative;
      height: 27px;
      p {
        margin: 0;
        padding: 0;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: absolute;
      }
    }
    .thumbnailszone {
      margin: 0;
      padding: 0;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid rgba(255, 255, 255, 0.137);
      padding-top: 10px;
      padding-bottom: 10px;
      .thumbnails {
        display: block;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;

        p {
          margin: 0;
          padding: 0;
          margin-left: 5%;
          font-size: calc(12px + 0.2vw);
          margin-bottom: 5px;
        }
        img {
          max-width: 90%;
          max-height: 90%;
          margin-left: 5%;
        }
      }
    }
  }
  .tablezone {
    position: relative;
    width: 100%;
    border: 1px solid var(--bodertable);
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .tabletitle {
      width: 100%;
      padding: 0;
      margin: 0;
      border-spacing: 0;
      border: 1px solid rgba(0, 0, 0, 0.459);
      border-bottom: none;
      background-color: white !important;

      tbody {
        border: none;
        padding: 0;
        margin: 0;
        border-spacing: 0;
        width: 100%;
        /* border: none; */
        /* padding: 0; */
        /* margin: 0; */
        display: flex;
        /* border-spacing: 0; */
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }
      tr {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        min-height: 35px;
        th {
          width: 33.333%;
          text-align: center;

          padding: 0;

          p {
            padding: 0;
            margin: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: calc(45px + 0.2vh);
            font-weight: 600;
          }
        }
      }
    }
    .tablecontent {
      border: none;
      padding: 0;
      margin: 0;
      border-spacing: 0;
      width: 100%;
      /* border: none; */
      /* padding: 0; */
      /* margin: 0; */
      display: flex;
      /* border-spacing: 0; */
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      tr {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        border-right: 1px solid var(--bodertable2);
        td {
          tr:first-child {
            td {
              border-top: none !important;
            }
          }
        }
      }

      tr {
        td:first-child {
          display: flex;
          flex-wrap: wrap;
        }
        td:last-child {
          tr {
            td {
              border-right: none;
            }
          }
        }
      }
      tr {
        width: 100%;
        flex-wrap: wrap;

        td {
          width: 33.333333333333%;
          text-align: center;
          overflow: hidden;
          border-right: none;
          border-top: 1px solid var(--bodertable2);
          padding: 0px;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          p {
            padding: 0;
            margin: 0;
            padding-top: 2px;
            padding-bottom: 2px;
            font-weight: 400;
          }
          tr {
            min-height: calc(40px + 0.2vh);
            td {
              margin: 0;
              padding: 0;
              position: relative;
              border-top: 1px solid var(--bodertable2) !important;
              width: 100%;
              p {
                padding: 0;
                margin: 0;
                padding-top: 2px;
                padding-bottom: 2px;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: 4px;
                padding-left: 4px;
                white-space: nowrap;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                font-weight: 500;
              }
            }
          }
        }
      }

      .leftcolor {
        position: absolute;
        left: 5px;
        width: 5px;
        height: 80%;
        margin-top: 1%;
      }
      .cyan {
        background-color: cyan;
      }
      .magenta {
        background-color: magenta;
      }
      .yellow {
        background-color: yellow;
      }
      .black {
        background-color: black;
      }
    }
  }
}
.globaltotal {
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;

  border: 1px solid var(--bodertable3);
  height: 40px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: none;
  left: -1px;
  bottom: -42px;
  .totaltitle {
    width: calc(33.33333%);
    background-color: rgb(243, 243, 243);
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: relative;
    p {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 0;
      padding: 0;
      font-weight: 500;
    }
  }
  .total {
    width: calc(66.66666%);
    background-color: rgb(243, 243, 243);
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    p {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      margin: 0;
      padding: 0;
      font-weight: 500;
    }
  }
}
.globaltotal2 {
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;

  border: 1px solid var(--bodertable3);
  height: 40px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: none;
  left: -1px;
  bottom: -42px;
  .totaltitle2 {
    width: 33.3333%;
    background-color: rgb(243, 243, 243);
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: relative;

    p {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      font-weight: 500;
    }
  }
  .total {
    width: 33.33333%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    p {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      font-weight: 500;
    }
  }
}
.borderright {
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  border-right: 1px solid var(--bodertable3);
}

.tdinput {
  position: relative;

  width: 100%;
  .input {
    width: 25px;
    position: absolute;
    left: 5px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    border-radius: 2px;
    outline: none;
    border: 1px solid #afafaf81;
    width: 20px;
    height: 20px;
  }

  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    left: 0;
    position: relative;
    cursor: pointer;
    filter: 1 !important ;
  }
  [type="checkbox"]:not(:checked) {
    background-color: transparent;
  }
  [type="checkbox"]:checked {
    background-image: url(../../img/check.svg);
    background-size: 95%;
    filter: invert(1);
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid #4444449a;
    padding: 0;
    background-color: rgba(201, 147, 0, 0.26);
  }
}
.opacity {
  opacity: 0.12;
}
.justrecto {
  width: 66.6666% !important;
  tr {
    border-right: none !important;
  }
}
.borderRightnone {
  border-right: none !important;
}

.foilTable {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.459);
  border-collapse: collapse;
  margin-top: 15px;
  background-color: white;
  th {
    height: 40px;
    font-weight: 500;
    font-size: var(--verylittlefont) !important;
    font-family: "Montserrat" !important;
    text-transform: uppercase;
  }

  td {
    border: 1px solid rgba(0, 0, 0, 0.459);
    font-size: calc(16px + 0.2vw) !important;
    font-weight: 500 !important;
    text-align: center;
    height: 40px;
  }
}

.resultTable {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.459);
  border-collapse: collapse;
  background-color: white;
  th {
    height: 40px;
    font-weight: 500;
    font-size: var(--verylittlefont) !important;
    font-family: "Montserrat" !important;
    text-transform: uppercase;
  }

  td {
    border: 1px solid rgba(0, 0, 0, 0.459);
    font-size: calc(16px + 0.2vw) !important;
    font-weight: 500 !important;
    text-align: center;
    height: 40px;
  }
  .leftcolor {
    display: flex;
    width: 5px;
  }
  .cyan {
    background-color: cyan;
  }
  .magenta {
    background-color: magenta;
  }
  .yellow {
    background-color: yellow;
  }
  .black {
    background-color: black;
  }
}

.onlyRecto {
  width: 66.66%;
}

.oneThird {
  width: 33.33%;
}

.oneFourth {
  width: 25%;
}

.halfThreeFourth {
  width: 37.5%;
}

.half {
  justify-content: center;
  display: inline-flex;
  border: 1px solid rgba(0, 0, 0, 0.459);
  border-top: none;
  border-bottom: none;
  height: 40px;
  align-items: center;
}
.left {
  width: 60%;
  border-left: none;
}
.right {
  width: 40%;
  border-right: none;
  border-left: none;
  font-size: 0.75em !important;
}

.centerColor {
  justify-content: center;
  width: 100%;
  align-items: center;
  align-self: center;
}

.importantBg {
  background-color: #c2c2c261;
}

.headBg {
  background-color: white !important;
  tr th {
    font-weight: 500;
    color: var(--textcolor);
    font-size: var(--verylittlefont);
    opacity: 0.9;
    text-align: center;
  }
}
